import { Link } from 'gatsby';
import $ from 'jquery'; 
import OwlCarousel from 'react-owl-carousel3';
import React, {useState, useRef} from 'react'

import { connect } from 'react-redux'

import { getFavoritesAction,clearFavoritesAction, deleteFavoriteAction, changeSelectedAction, updaterFavoritesAction } from '../redux/propertiesDucks'
import { addMarker } from '../helpers/helper.map';

//Helpers
import { getCover,getPreviewSlider, getQuality, ellipsisText } from '../helpers/helper.rendering';
import { addFavorites, getfavorites, checkFavorite } from '../helpers/helper.favorites';
import { 
    getOperations,
    getType,
    getEnvironment,
    getSurface,
    getLocation,
    getFakeAddres,
    getPrices,
    makeLink,
    getTitle
    } from '../helpers/helper.properties';

import toast, { Toaster } from 'react-hot-toast';
import { graphql, useStaticQuery } from 'gatsby';

const Card = (props) => {

    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              name
          }
    }`)

    const { property, settings, dispatch, skeleton, opacity, handlerClick, map, setZoom } = props

    const [listFavorites,setListFavorites] = useState(getfavorites("prop",settings.short_name))
    const slider = useRef()

    const toastCheck = (id,fake_address) => {
        if(!checkFavorite(id,"prop",settings.short_name))
            return toast.success(`${fake_address} agregada a tus favoritos`) 
        else
            return toast.success(`${fake_address} eliminada de tus favoritos`)
    }

    const addHoverMap = (id) => {
        $('#marker-' + id).addClass(' hover-marker');
    }
    const removeHoverMap = (id) => {
        $('#marker-' + id).removeClass(' hover-marker');
    }
    const formatTitle = (property) =>{
        if(!props.inMap && !props.grid_map){

            return ellipsisText(getTitle(property),55)
        }
        return ellipsisText(getTitle(property),20)
    }

    // console.log(property)

    return (
        <div 
        onMouseEnter={() => addHoverMap(property?.id)}
        onMouseLeave={() => removeHoverMap(property?.id)} 
        id={"card-grid-"+ (props.grid_map && property.id)}
        onClick={() => (
            addMarker(property.id) +
            (props.grid_map && dispatch(changeSelectedAction(property.id))) +
            handlerClick?.(property.geo_lat,property.geo_long,map) + setZoom?.(17))}
        class={"card prop-card " + (props.inMap ? ' map-card ' : '') + (opacity ? ' opacity-card ' : '') + (props.grid_map ? ' grid-card  ' : '') + (skeleton && ' skeleton ')}>
            <div class={"card-image " + (props.grid_map && "grid_map_image")}>
                { props.basic
                        ?   <a target="_blank" href={props.grid_map ? '' : makeLink(property)}>
                                <img src={getQuality(getCover(property?.photos)) } className={'notScale '} alt={realEstate?.name} />
                            </a>
                    :   <>
                            {
                            property?.photos && 
                                <>
                                    <OwlCarousel ref={slider}
                                        items={1}
                                        margin={0}
                                        loop={true}
                                        className="overflow-hidden card-carousel">
                                            {
                                                (getPreviewSlider(property?.photos)).map((photo, index) => (
                                                    props.grid_map 
                                                    ? <img src={index === 0 ?  getQuality(photo) : getQuality(photo)} className='notScale' alt={realEstate?.name} />

                                                    :   <a target="_blank" href={makeLink(property)} key={index}>
                                                            {<img src={index === 0 ?  getQuality(photo) : getQuality(photo)} className='notScale' alt={realEstate?.name} />}
                                                        </a>
                                                ))
                                            }
                                    </OwlCarousel>
                                    <div className={"arrows " + (getPreviewSlider(property?.photos).length > 1 ? '' : 'd-none')}>
                                        {/* <div className="prev cursor-pointer" onClick={() => slider.current.prev()}>{"<"}</div>
                                        <div className="next cursor-pointer" onClick={() => slider.current.next()}>{">"}</div> */}
                                        <div className="prev cursor-pointer" onClick={() => slider.current.prev()}><span>{"<"}</span></div>
                                        <div className="next cursor-pointer" onClick={() => slider.current.next()}><span>{">"}</span></div>
                                    </div>
                                </> 
                            }
                        </> 
                        }
                { property && 
                    <div  class="badges">
                        {getOperations(property).map((operation, index) => (
                            <div key={index} className={"badge " + operation.toLowerCase()}>{operation}</div>
                        ))}
                    </div> }
                <div data-bs-dismiss="modal" onClick={() => dispatch(changeSelectedAction(0))} className="close-card"><i></i><i></i></div>
                <span onClick={() => dispatch(updaterFavoritesAction()) + toastCheck(property?.id, property?.fake_address) + addFavorites(property?.id,"prop",settings.short_name) + setListFavorites(getfavorites("prop",settings.short_name)) + dispatch(deleteFavoriteAction(property?.id))} className={"d-lg-inline-flex icon-solid-like btn fav " +  (listFavorites.find(element => element.toString() === property?.id?.toString()) ? 'active' : '')}  style={{zIndex:"8"}}>
                </span>
            </div>
            <div class="card-body">
                <div class="content-extra row d-flex justify-content-between align-items-center">
                    <div class={"col-12 col-lg-12 mb-3 extra-data d-flex justify-content-between align-items-center "}>
                        <div class="tags d-flex align-items-start">
                            <div class="tag">
                                {getType(property)}
                            </div>
                            <div class={"tag " + (getEnvironment(property) === 0 ? "d-none" : "")}>
                                {getEnvironment(property)} ambiente{getEnvironment(property) > 1 ? 's' : ''}
                            </div>
                        </div>
                        <span class={(props.grid_map ? 'd-none' : '')}>{getSurface(property)}</span>
                    </div>
                </div>
                <div class="content-extra">
                    <h3 class="card-title">
                        {
                            !props.grid_map
                            ? <a target="_blank" href={makeLink(property)}>{getFakeAddres(property)}</a>
                            : <a>{getFakeAddres(property)}</a>
                        }
                        
                    </h3>  
                <span className="location-property"><i class="icon-pin"></i> {getLocation(property) + (property?.location?.full_location.split("|")[2].includes(getLocation(property)) ? '' : (', ' +  property?.location?.full_location.split("|")[2]))}</span>
                    <p className="py-4 pb-0 text-uppercase publication-title">
                        {(props.grid_map ? getSurface(property) : formatTitle(property))}
                    </p>
                    <div className="d-flex justify-content-between align-items-end mt-4 bottom-card">
                        <div className="d-flex flex-column">
                            {getPrices(property).map((price, index) => (
                                <>
                                    <p class={"prop-price " + price.type }>
                                        {price.currency} {price.price > 0 ? Intl.NumberFormat("de-DE").format(price.price) : price.price}
                                        {price.type === 'Alquiler' && property.expenses ?  <span><br /> EXP: ARS {Intl.NumberFormat("de-DE").format(property.expenses)}</span>:''}
                                    </p>
                                </>

                            ))} 
                        </div>
                        <a target="_blank" href={makeLink(property)} class={"btn btn-plus " + (props.inMap || props.grid_map ? '' : 'big')}><span>VER {props.inMap || props.grid_map ? '+' : 'MÁS'}</span></a>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default connect(state => ({
    settings: state.settings,
}),null)(Card);